import { Link } from "react-router-dom";
import { faArrowTrendUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateTimeString } from "../../../services/DateTime";

const RecentSearches = ({ list }) => {
  return (
    <div className="mt-5">
      <h5>Recent Searches</h5>
      <div className="mt-3 pt-5 bg-white rounded p-3 border border-neutral-300 min-h-[169px]">
        {Array.isArray(list) && list.length > 0 ? (
          <div>
            {list.map((basicSearch, i) => {
              return (
                <div
                  className={`${
                    i === 0 ? "" : "mt-4"
                  } border px-3 py-3 relative`}
                >
                  <Link
                    to={`/credit_management/entity/${basicSearch?.entityUuid}`}
                    className="w-full"
                  >
                    <div className="flex items-center">
                      <FontAwesomeIcon
                        icon={faArrowTrendUp}
                        className="text-neutral-600"
                      />
                      <div
                        className="ml-3 whitespace-nowrap w-full"
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "inline-block"
                        }}
                      >
                        <span className="text-cyan-600 font-semibold underline">
                          {basicSearch.entityName}
                        </span>
                        <span className="bg-gray-100 !text-neutral-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded ml-2">
                          {basicSearch.entityType}
                        </span>
                      </div>
                    </div>
                  </Link>

                  <div className="text-neutral-500 absolute top-[-10px] right-[10px] bg-white border px-3 text-xs">
                    {formatDateTimeString(basicSearch?.createdAt)}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center flex items-center justify-center h-full">
            <div className="my-8">
              <FontAwesomeIcon
                icon={faSearch}
                size="2x"
                className="text-neutral-300 mb-3"
              />
              <div>No recent searches</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentSearches;
