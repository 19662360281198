/* eslint-env browser */

import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";

import { GlobalHeader } from "@bafsllc/ui-shared";

import DocTitle from "./components/DocTitle";

import { Institutions } from "./services/ApiLib";
import NotificationsProvider from "./components/Notifications/NotificationsProvider";
import ValidationRules from "./services/ValidationRules";
import {
  CheckAuth,
  UnsetToken,
  RefreshToken,
  isBafs,
  userCanViewPage
} from "./services/Auth";
import useInstitutions from "./services/Hooks/useInstitutions/useInstitutions";

import Navigation from "./components/Navigation";
import { ErrorModal } from "./components/Modals";
import NotificationsSidebar from "./components/NotificationsSidebar";
import AssetManagement from "./scenes/AssetManagement";
import AutoDecision from "./scenes/AutoDecision";
import { AppStatus } from "./scenes/AppStatus";
import CreditManagement from "./scenes/CreditManagement";
import CurrentRequest from "./scenes/CurrentRequest";
import Dashboard from "./scenes/Dashboard";
import DocMgmt from "./scenes/DocMgmt";
import EntityManagement from "./scenes/EntityManagement";
import EntityManagementAdvancedSearch from "./scenes/EntityManagement/AdvancedSearch";
import Forms from "./scenes/Forms";
import LoanManagement from "./scenes/LoanManagement";
import Login from "./scenes/login";
import LOS from "./scenes/LOS";
import ConnectedMonthEndFinalization from "./scenes/MonthEndFinalization";
import PasswordReset from "./scenes/PasswordReset";
import PPPCurrentRequest from "./scenes/CurrentRequest/PPPCurrentRequests";
import LoanReviewRequest from "./scenes/CurrentRequest/LoanReviewRequests";
import Settings from "./scenes/Settings";
import SystemAdmin from "./scenes/SystemAdmin";
import WelcomePage from "./scenes/WelcomePage";
import reduxStore from "./store";
import messages from "./assets/messages/common.en-US.json";
import { registerAll } from "./services/Register3rdParties";
import logger from "./services/logger";
import { BlastSessionManager } from "@bafsllc/auth-shared";
import {
  ENTITY_MANAGEMENT,
  ENTITY_MANAGEMENT_ADVANCED_SEARCH
} from "./constants/routes";

ValidationRules();

// TODO: remove `store` and replace with `useDispatch` and `useSelector`.
let store = reduxStore;

function Logout() {
  UnsetToken();
  store.dispatch({ type: "LOGOUT" });
  Sentry.setUser(null);

  if (window.location.pathname !== "/login") {
    if (window.location.href.includes("/logout")) {
      // User was logged out due to inactivity
      window.location.href = "/login";
    } else {
      // User chose to log out
      window.location.href = `/login?redirect=${encodeURIComponent(
        window.location.href
      )}`;
    }
  }

  return null;
}

export const checkTokenExpiration = () => {
  if (
    !CheckAuth() &&
    !window.location.pathname.includes("/login") &&
    !window.location.pathname.includes("/password_reset")
  ) {
    store.dispatch({ type: "LOGOUT" });
    window.location.href = `/login?redirect=${encodeURIComponent(
      window.location.href
    )}`;
  }
};

const propertiesToGet = ["institutions"];

export function PrivateRoute({
  component: Component,
  spa,
  noNav,
  path,
  ...rest
}) {
  const { auth, SystemAdminReducer, NotificationsReducer } = store.getState();
  const institutions = SystemAdminReducer.allInstitutions;
  const unreadCount = NotificationsReducer.unreadCount;

  const dispatch = useDispatch();
  const { institutions: institutionsFetchStatus } = useInstitutions({
    dispatch,
    propertiesToGet
  });

  const user = {
    givenName: auth.firstName || "",
    surname: auth.lastName || "",
    userUuid: auth.userUuid || "",
    institutionUuid: auth.institutionUuid
  };

  const redirect = pathName => (
    <Route {...rest} render={() => <Redirect to={{ pathname: pathName }} />} />
  );

  if (!CheckAuth()) {
    Logout();
  }

  if (!path || !userCanViewPage(path)) {
    logger.info(
      `User ${auth.userUuid} has insufficient permissions to view: ${path}`
    );
    return redirect("/");
  }

  RefreshToken();

  const toggleNotificationsPanel = () => {
    dispatch({ type: "NOTIFICATIONS_PANEL" });
  };

  const globalHeaderOptions = {
    baseHref: "/",
    logout: Logout,
    portalName: "blast",
    user
  };

  if (isBafs()) {
    globalHeaderOptions.isBafsUser = true;
    globalHeaderOptions.institution = {
      institutions,
      institutionSelected: null,
      onInstitutionSelected: () => []
    };
    if (
      institutionsFetchStatus?.status === "idle" &&
      institutionsFetchStatus?.data
    ) {
      const found = institutions.find(
        institution => institution.uuid === user.institutionUuid
      );
      globalHeaderOptions.institution = {
        ...globalHeaderOptions.institution,
        institutionSelected: found
      };
    }
  } else {
    globalHeaderOptions.title = { defaultMessage: auth.institutionName ?? "" };
  }

  return (
    <Route
      {...rest}
      render={props =>
        auth.authenticated === false ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <div className="app-container">
            {spa && noNav !== "true" && (
              <GlobalHeader
                {...globalHeaderOptions}
                toggleNotificationsPanel={toggleNotificationsPanel}
                unreadNotificationsCount={unreadCount}
              />
            )}
            <div className="sub-header-container">
              {spa && noNav !== "true" && <Navigation />}
              <div
                className={
                  spa ? "application-content-container flex-1 p-6" : ""
                }
              >
                <BlastSessionManager />
                <ErrorModal />
                <Component {...props} />
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

PrivateRoute.defaultProps = {
  noNav: "true"
};

PrivateRoute.propTypes = {
  spa: PropTypes.bool.isRequired,
  noNav: PropTypes.string,
  path: PropTypes.string,
  component: PropTypes.elementType.isRequired
};

function Commotion({ spa, storeOverride }) {
  if (!spa) {
    store = storeOverride;
  }

  const token = CheckAuth();
  const getUserData = async () => {
    const institution = await Institutions.asyncRead({
      institutionUuid: token.institution_uuid
    });
    if (!institution)
      throw new Error(
        `Institution ${token.institution_uuid} from the token was not found`
      );
    store.dispatch({
      type: "AUTH_GET_INST_DATA",
      data: institution.data
    });
  };

  if (token !== undefined) {
    const data = {
      token: token.token,
      user_uuid: token.uuid,
      institution_uuid: token.institution_uuid,
      first_name: token.first_name,
      last_name: token.last_name
    };
    store.dispatch({ type: "GET_LOGIN", data });

    if (!store.getState().auth.name) {
      getUserData();
    }

    registerAll(token);
  }
  window.setInterval(checkTokenExpiration, 5000); // 5 seconds

  const query = new URLSearchParams(window.location.search);
  const noNav = query.get("nonav");

  return (
    <div>
      <DocTitle title="BLAST Portal" />
      <Provider store={store}>
        <IntlProvider locale="en-US" messages={messages}>
          <NotificationsProvider>
            <Router>
              <Switch>
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  exact
                  path="/"
                  component={Dashboard}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  exact
                  path="/application-phases"
                  component={CurrentRequest}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  exact
                  path="/ppp-applications"
                  component={PPPCurrentRequest}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  exact
                  path="/loan-review-requests"
                  component={LoanReviewRequest}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/asset_management"
                  component={AssetManagement}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/auto_decision_request"
                  component={AutoDecision}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/docs"
                  component={DocMgmt}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/credit_management"
                  component={CreditManagement}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/loan_management"
                  component={LoanManagement}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/los"
                  component={LOS}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path={ENTITY_MANAGEMENT_ADVANCED_SEARCH}
                  component={EntityManagementAdvancedSearch}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path={ENTITY_MANAGEMENT}
                  component={EntityManagement}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/forms"
                  component={Forms}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/month_end_finalization"
                  component={ConnectedMonthEndFinalization}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/settings"
                  component={Settings}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/system_admin/:tab"
                  component={SystemAdmin}
                />
                <Route path="/logout" component={Logout} />
                <Route path="/login" component={Login} />
                <Route
                  exact
                  path="/password_reset/:uuid"
                  render={({ match }) => (
                    <PasswordReset uuid={match.params.uuid} />
                  )}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/welcome"
                  component={WelcomePage}
                />
                <PrivateRoute
                  spa={spa}
                  noNav={noNav}
                  path="/app_status"
                  component={AppStatus}
                />
                <Redirect to={{ pathname: "/" }} />
              </Switch>
              <NotificationsSidebar />
            </Router>
          </NotificationsProvider>
        </IntlProvider>
      </Provider>
    </div>
  );
}

Commotion.defaultProps = {
  storeOverride: undefined
};

Commotion.propTypes = {
  spa: PropTypes.bool.isRequired,
  storeOverride: PropTypes.shape({
    auth: PropTypes.shape({
      jwt: PropTypes.string,
      loginFailed: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      userUuid: PropTypes.string
    })
  })
};

export default Commotion;
